import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './FileInput.module.scss';
import i18n from '../../../i18n';
import { FileInputType } from '../../../internal';
import { ReactNode, useRef, useState } from 'react';

interface IFileInput {
  model: FileInputType;
  LabelRenderer: ({ className }: { className?: string }) => JSX.Element;
  ImageWrapper: ({ children }: { children: ReactNode }) => JSX.Element;
  GalleryRenderer?: (model: FileInputType, className?: string) => JSX.Element;
  onFileSizeError?: boolean;
  onFileTypeError?: (message?: string) => void;
  id?: string;
  fridayScratchbanners?: boolean;
  companyProfileImage?: boolean;
}

export const FileInput = observer((props: IFileInput) => {
  const {
    model,
    LabelRenderer,
    GalleryRenderer,
    ImageWrapper,
    id,
    onFileSizeError,
    fridayScratchbanners,
    companyProfileImage,
  } = props;
  const [entered, setEntered] = useState<boolean>(false);
  const inputEl = useRef<HTMLInputElement>(null);

  const checkImageSize = (
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width !== maxWidth || img.height !== maxHeight) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
      img.onerror = () => reject(false);
      img.src = URL.createObjectURL(file);
    });
  };

  const checkImageSizeForFridayBanners = (
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > maxWidth || img.height > maxHeight) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
      img.onerror = () => reject(false);
      img.src = URL.createObjectURL(file);
    });
  };

  const handleFiles = async (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const fileArray = Array.from(files);

    if (fridayScratchbanners) {
      const maxHeight = 1380;
      const maxWidth = 1080;
      for (const file of fileArray) {
        const isValidSize = await checkImageSizeForFridayBanners(
          file,
          maxWidth,
          maxHeight
        );
        if (!isValidSize) {
          model.root.showFailure(i18n.t('offer:must_be_1080x1380'));
          return;
        }
      }
    }

    if (companyProfileImage) {
      const maxHeight = 300;
      const maxWidth = 300;
      for (const file of fileArray) {
        const isValidSize = await checkImageSizeForFridayBanners(
          file,
          maxWidth,
          maxHeight
        );
        if (!isValidSize) {
          model.root.showFailure(i18n.t('offer:must_be_300x300'));
          return;
        }
      }
      model.clearFiles();
    }

    if (onFileSizeError) {
      const maxWidth = 600;
      const maxHeight = 300;
      for (const file of fileArray) {
        const isValidSize = await checkImageSize(file, maxWidth, maxHeight);
        if (!isValidSize) {
          model.root.showFailure(i18n.t('offer:must_be_600x300'));
          return;
        }
      }
    }

    model.setInputRef(inputEl);
    model.processFiles({
      target: { files },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const onDragEnter = () => setEntered(true);
  const onDragLeave = () => setEntered(false);

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setEntered(false);
    const files = e.dataTransfer.files;
    await handleFiles(files);
  };

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    await handleFiles(files);
  };

  const filesArray = model?.filesArray;

  if (!model) {
    return null;
  }

  return (
    <div
      id={id}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={styles.Upload}
    >
      <label
        className={styles.FileInputLabel}
        id={`${model.id}_label`}
        htmlFor={model.id}
      >
        {LabelRenderer ? (
          filesArray[0] ? (
            <ImageWrapper>
              <img
                alt={''}
                src={model.crop || URL.createObjectURL(filesArray[0])}
              />
            </ImageWrapper>
          ) : model?.src ? (
            <ImageWrapper>
              <img alt={''} src={model.src} />
            </ImageWrapper>
          ) : (
            <LabelRenderer className={entered ? 'Entered' : ''} />
          )
        ) : (
          model.value
        )}
      </label>
      <input
        ref={inputEl}
        data-id={model.id}
        type={'file'}
        accept="image/*"
        id={model.id}
        name={model.name}
        hidden={!!LabelRenderer}
        onInput={onChange}
        multiple
        disabled={!!model.file && !companyProfileImage}
      />
    </div>
  );
});
