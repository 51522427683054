import { getRoot, IAnyModelType, types } from 'mobx-state-tree';
import i18n from '../../../../i18n';
import {
  SectionModel,
  CreateOfferPageModel,
  RootType,
  CheckboxModel,
  CheckboxGroupModel,
  CheckboxModelType,
} from '../../../../internal';
import { STANDARD } from '../../../../constants';
import { endOfYesterday, isBefore } from 'date-fns';

export const OfferSectionModel: IAnyModelType = types
  .model({
    parent: types.maybe(
      types.reference(types.late(() => CreateOfferPageModel))
    ),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      getOfferSection() {
        return (
          self?.parent?.components.get('OfferSection') ||
          self?.parent?.addComponent(
            SectionModel.create({
              id: 'OfferSection',
              label:
                self.root.data.isPromoPeriodOver && self.root.data.isPayAsYouGo
                  ? `${i18n.t('basic:basic_ad')} +${
                      self.root.data.currentCatalog.price_basic
                    } RSD *dan`
                  : i18n.t('basic:basic_ad'),
              opened: true,
            })
          )
        );
      },
      getAdTypeSection() {
        return (
          self?.parent?.components.get('AdTypeSection') ||
          self?.parent?.addComponent(
            SectionModel.create({
              id: 'AdTypeSection',
              label: 'basic:ad_type_selection',
              opened: true,
            })
          )
        );
      },
      resetTargetAndLocationPick() {
        self?.parent?.getNumberOfCoupons().setValue('0');
      },
      getLocationPickSection() {
        return (
          self?.parent?.components.get('LocationPickSection') ||
          self?.parent?.addComponent(
            SectionModel.create({
              id: 'LocationPickSection',
              label: 'basic:location_pick',
              opened: true,
            })
          )
        );
      },
      getTargetSectionCheckbox() {
        return (self.root.data.isPromoPeriodOver &&
          self.root.data.isPayAsYouGo) ||
          self.root.data.isPromoPackage
          ? self?.parent?.components.get('UserTargetCheckboxGroup') ||
              self?.parent?.addComponent(
                CheckboxGroupModel.create({
                  id: 'UserTargetCheckboxGroup',
                  checkboxes: {
                    UserTargetCheckbox: CheckboxModel.create({
                      onChangeCallback: (selfie: CheckboxModelType) => {
                        this.getTargetSection().setEditMode(selfie.isChecked);
                      },
                      isEnabledFunc: () => !self.parent?.isUpdateMode,
                      id: 'UserTargetCheckbox',
                      label:
                        self.root.data.isPromoPeriodOver &&
                        self.root.data.isPayAsYouGo
                          ? `${i18n.t('basic:user_targeting')} +${
                              self.root.data.currentCatalog?.price_targeted
                            } RSD *dan`
                          : i18n.t('basic:user_targeting'),
                    }),
                  },
                  allSelectedOnInit:
                    self.root.currentPage.offerInfo?.type === 'special',
                })
              )
          : undefined;
      },
      getTargetSection() {
        const {
          router: { isCreateOfferView },
        } = self.root;

        return (
          self?.parent?.components.get('TargetSection') ||
          self?.parent?.addComponent(
            SectionModel.create({
              id: 'TargetSection',
              label: 'basic:user_targeting',
              opened: true,
              editMode: true,
            })
          )
        );
      },
      disableTargetSection() {
        self?.parent?.getGenderDropdown().setDisabled(true);
        self?.parent?.getFromAge().setDisabled(true);
        self?.parent?.getToAge().setDisabled(true);
        self?.parent?.getUserSegments().setDisabled(true);
        self?.parent?.getMobileNetworkSpending().setDisabled(true);
        self?.parent?.getUsersPickedSimilarOffersDropdown().setDisabled(true);
        self?.parent?.getAreaDropdown().setDisabled(true);
        self?.parent?.getAdditionalUsers().setDisabled(true);
      },
      getEnterCodeSection() {
        return (
          self?.parent?.components.get('EnterCodeSection') ||
          self?.parent?.addComponent(
            SectionModel.create({
              id: 'EnterCodeSection',
              opened: true,
              label: 'basic:enter_codes',
            })
          )
        );
      },
      enableTargetSection() {
        this.getTargetSection().setEditMode(true);
      },
      disableEnterCodeSection() {
        self?.parent?.getEnterCodesModel().setDisabled(true);
      },
      getUserPreviewSection() {
        return (
          self?.parent?.components.get('UserPreviewSection') ||
          self?.parent?.addComponent(
            SectionModel.create({
              id: 'UserPreviewSection',
              label: 'basic:user_preview',
              opened: true,
            })
          )
        );
      },
      getBillSpecificationSection() {
        return (
          self?.parent?.components.get('BillSpecificationSection') ||
          self?.parent?.addComponent(
            SectionModel.create({
              id: 'BillSpecificationSection',
              label: 'basic:bill_specification',
              opened: true,
            })
          )
        );
      },
      getPaymentSummarySection() {
        return (
          self?.parent?.components.get('PaymentSummarySection') ||
          self?.parent?.addComponent(
            SectionModel.create({
              id: 'PaymentSummarySection',
              label: 'basic:payment',
              opened: true,
            })
          )
        );
      },
      // validation code logic for sections

      submitFirstSection(): boolean {
        if (this.validateFirstSection()) {
          return true;
        } else {
          self.root.showFailure(i18n.t('forms:invalid_section'));
          return false;
        }
      },
      submitSecondSection(): boolean {
        if (this.validateSecondSection()) {
          return true;
        } else {
          self.root.showFailure(i18n.t('forms:invalid_section'));
          return false;
        }
      },
      submitThirdSection(): boolean {
        if (this.validateFirstSection() && this.validateSecondSection()) {
          return true;
        } else {
          self.root.showFailure(i18n.t('forms:invalid_section'));
          return false;
        }
      },
      validateFirstSection(): boolean {
        let result = true;
        let elements = self?.parent?.componentsArray.filter(
          (firstSection) => firstSection.step === 1
        );
        const isOfferWithoutVoucher =
          self?.parent?.components.get('OfferTypeContainer').value ===
          'OfferWithoutVoucher';

        // if the offer is without voucher, these two text fields
        // terms and descriptions are not validated
        // everything else goes
        if (isOfferWithoutVoucher) {
          elements = elements?.filter(
            (component) =>
              component.id !== 'OfferTerms' &&
              component.id !== 'OfferDescription'
          );
        }

        elements?.forEach((component) => {
          component.runValidators();
          if (result && component.currentMessage) {
            result = false;
          }
        });
        const offerLocationPickValid = this.validateOfferLocationPick();
        const atLeastOneImagePicked = this.validateImagePick();
        const pricesOrDiscountAtLeastEntered = this.validatePricesAndDiscount();
        const categorySelected = this.validateCategoryPick();
        const datesValid = this.validateVoucherDuration();
        const keywordsValid = this.validateKeywords();
        return (
          offerLocationPickValid &&
          atLeastOneImagePicked &&
          pricesOrDiscountAtLeastEntered &&
          categorySelected &&
          datesValid &&
          keywordsValid &&
          result
        );
      },
      validateKeywords() {
        let result = true;
        const keyWords = self?.parent?.getKeywords();
        if (keyWords.value.length >= keyWords.characterLimit) {
          self.root.showFailure(
            i18n.t('offer:keywords_must_be_255_characters')
          );
          result = false;
        }
        return result;
      },
      validateCategoryPick() {
        let result = true;
        const categoryPick = self?.parent?.getCategoryAndSubcategoryPickModel();
        if (!categoryPick.selectedOptionsArray.length) {
          self.root.showFailure(
            i18n.t('offer:must_choose_category_subcategory')
          );
          result = false;
        }
        return result;
      },
      validateVoucherDuration() {
        let result = true;
        const voucherDuration =
          self?.parent?.components?.get('VoucherDuration');
        const offerDuration = self?.parent?.components?.get('offerDuration');
        if (
          !offerDuration?.isDisabled &&
          isBefore(new Date(offerDuration?.fromDate), endOfYesterday())
        ) {
          self.root.showFailure(i18n.t('offer:campaign_cant_be_in_past'));
          result = false;
        }
        if (
          !voucherDuration?.isDisabled &&
          isBefore(new Date(voucherDuration?.fromDate), endOfYesterday())
        ) {
          self.root.showFailure(i18n.t('offer:voucher_cant_be_in_past'));
          result = false;
        }
        return result;
      },
      validatePricesAndDiscount() {
        let result = true;
        const components = self?.parent?.components;
        const loweredPriceComponent = components?.get('LoweredPriceRSD');
        const regularPriceComponent = components?.get('RegularPriceRSD');
        if (loweredPriceComponent.value && !regularPriceComponent.value) {
          regularPriceComponent.setCurrentMessage(
            i18n.t('validators:required_validator')
          );
          result = false;
        }
        return result;
      },
      validateImagePick() {
        let result = true;
        if (!self?.parent?.imagesArray?.length) {
          self.root.showFailure(i18n.t('forms:no_images_selected'));
          result = false;
        }
        return result;
      },
      validateOfferLocationPick(): boolean {
        const locationPickValue = self?.parent?.getLocationPickModel().value;
        const storesModal = self?.parent?.getPickStoresModal();
        let result = true;
        const individualStoresPicked =
          locationPickValue === 'selected' ||
          locationPickValue === 'online-sel';
        if (individualStoresPicked && !storesModal.anythingSelected) {
          self.root.showFailure(i18n.t('forms:no_physical_stores_selected'));
          result = false;
        }
        return result;
      },
      validateCodeTable(): boolean {
        // there is no section in this case, so it passes automatically
        // or the section is disabled
        if (
          self?.parent?.isWithoutVoucher ||
          self?.parent?.getEnterCodesModel().isDisabled
        ) {
          return true;
        }
        const result = true;
        // const isPersonalizedCodeType =
        //   self?.parent?.components.get('UploadCodesModel')?.codeTypeDropdown
        //     ?.value === 'personalized';
        // if (isPersonalizedCodeType && !self?.parent?.codeTablesEntered) {
        //   rootInstance.showFailure(i18n.t('forms:codes_must_be_imported'));
        //   result = false;
        // }
        return result;
      },
      validateSecondSection(): boolean {
        // there is no second step in this case, so it passes automatically
        if (self?.parent?.standardPackageWithoutVoucher) {
          return true;
        }
        let result = true;
        const isStandardOffer =
          self?.parent?.components.get('PackagePick').value === STANDARD;

        let elements: any[] | any = self?.parent?.componentsArray.filter(
          (component) => component.step === 2
        );

        // if the offer is of the standard variety then everything
        // besides code tables on the second step is not validated
        if (isStandardOffer) {
          elements = elements.filter(
            (component: any) => component.id === 'UploadCodesModel'
          );
        }

        // if there is no voucher everything BUT the upload codes is validated
        if (self?.parent?.isWithoutVoucher) {
          elements = elements.filter(
            (component: any) => component.id !== 'UploadCodesModel'
          );
        }

        elements?.forEach((component: any) => {
          component.runValidators();
          if (result && component.currentMessage) {
            result = false;
          }
        });
        const tableValidation = this.validateCodeTable();
        return tableValidation && result;
      },
    };
  })
  .named('OfferSectionModel');
