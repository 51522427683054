import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FileInputType, useMst } from '../../../../internal';
import styles from '../../../../pages/company-profile/CompanyProfile.module.scss';
import { Dropdown } from '../../../UI/dropdown/Dropdown';
import { InputField } from '../../../UI/input/InputField';
import { Button } from '../../../UI/buttons/regular/Button';
import * as React from 'react';
import { InfoField } from '../../../UI/info-field/InfoField';
import { ReactNode } from 'react';
import plus from '../../../../layouts/images/icon-add.svg';
import { FileInput } from '../../../UI/file-input/FileInput';
import { IMAGES_BASE_URL } from '../../../../utils/api/images';
import { PARTNER } from '../../../../constants/roleTypes';

export const CompanyHeadquarters = observer((): JSX.Element => {
  const { t } = useTranslation(['company-profile']);
  const {
    user: { role },
    currentPage,
    data: {
      partnerDetails: { image },
    },
  } = useMst();

  const fileInputs = currentPage.getFileInputs();
  return (
    <section>
      <div className={styles.Content}>
        <div className={styles.Column}>
          <InfoField model={currentPage.getCompanyNameModel()} />
          <div className={styles.InlineContent}>
            <InfoField model={currentPage.getPibModel()} />
            <InfoField model={currentPage.getMbrModel()} />
          </div>
          <InfoField model={currentPage.getCompanyAddressModel()} />
          <InfoField model={currentPage.getCompanyCityModel()} />
          <InfoField model={currentPage.getCompanyStreetModel()} />
          <InfoField model={currentPage.getCompanyStreetNumberModel()} />
          <InfoField model={currentPage.getCompanyApartmentNumberModel()} />
          <InfoField model={currentPage.getCompanyZipModel()} />
          <InfoField model={currentPage.getCompanyPakModel()} />

          {/*hidden per client's request*/}
          {/*<InfoField model={currentPage.getWindPay()} />*/}
          {/*<InfoField model={currentPage.getOfferType()} />*/}
        </div>
        <div className={styles.Column}>
          <InfoField model={currentPage.getBillingAddressModel()} />
          <InfoField model={currentPage.getBillingCityModel()} />
          <InfoField model={currentPage.getBillingStreetModel()} />
          <InfoField model={currentPage.getBillingStreetNumberModel()} />
          <InfoField model={currentPage.getBillingApartmentNumberModel()} />
          <InfoField model={currentPage.getBillingZipModel()} />
          <InfoField model={currentPage.getBillingPakModel()} />
          <InfoField model={currentPage.getOfferType()} />
        </div>
        <div className={styles.Column}>
          <Dropdown model={currentPage.getCategoryModel()} />
          <InputField model={currentPage.getContactPhoneResponsiblePerson()} />
          <InputField model={currentPage.getTelenorAgentEmail()} />
          <InputField model={currentPage.getWebAddress()} />
          <InputField model={currentPage.getEmailForConsumersContact()} />
          <InputField model={currentPage.getPhoneForConsumersContact()} />
          <InputField model={currentPage.getCompanyNameOnAds()} />
          {role.name !== PARTNER && (
            <Dropdown model={currentPage.getSegmentModel()} />
          )}
        </div>
        <div className={styles.Column}>
          <label className={styles.Label}>{t('company_profile:logo')}</label>
          <div
            className={`${styles.FileInputContainer} ${
              currentPage.isEditMode ? styles.Disabled : ''
            }`}
          >
            {fileInputs.map((fileInput: FileInputType, index: number) => {
              return (
                <div key={index} className={styles.FileInput}>
                  <FileInput
                    model={fileInputs[index]}
                    ImageWrapper={({ children }: { children: ReactNode }) => {
                      return (
                        <div className={styles.UploadContainer}>{children}</div>
                      );
                    }}
                    LabelRenderer={() => (
                      <div className={styles.UploadContainer}>
                        {!fileInput.filesArray[0] && (
                          <img
                            alt={'Logo'}
                            className={image ? '' : styles.PlusIcon}
                            src={
                              image
                                ? new URL(image, IMAGES_BASE_URL).href
                                : plus
                            }
                          />
                        )}
                      </div>
                    )}
                    companyProfileImage={true}
                  />
                  {fileInputs[index] && (
                    <label
                      htmlFor={fileInputs[index].id}
                      className={styles.ChangePicture}
                    >
                      Izmeni
                    </label>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <section className={styles.ButtonContainerBackground}>
        <div className={styles.ButtonContainer}>
          {currentPage.isEditMode ? (
            <Button model={currentPage.getEditButton()} />
          ) : (
            <Button model={currentPage.getSaveButton()} />
          )}
        </div>
      </section>
    </section>
  );
});
